import '@/iconfont/iconfont.css';
import { masRecord } from '@didi/mas-web';
import { PhoneNoAccessIcon } from '@/assets/icon';
import ErrorTips from '@/components/ErrorTipsDk';
import LayoutCooper from '@/components/LayoutCooper';
import LayoutDK from '@/components/serviceComponents/Layout';
import api from '@/utils/request/api/CooperApi';
import { ConfigProvider, message } from 'antd';
import store, { history } from '@/model';
import { getCooperRoute } from '@/routes/route-cooper';
import { getDkRoute } from '@/routes/route-dk';
import SkeletonPage from '@/components/SkeletonPage';
import { getGlobalRoute, TenantLoginPage } from '@/routes/router-global';
import { setConnectedSiteId, getCooperNoticeFromBackEnd, getCooperErrorFromBackEnd, getHaloTips } from '@/service/cooper/index';
import asyncInitSdk from '@/service/knowledge/asyncInitSdk';
import { inPhone, isSupportPreview, setCookie, isDC } from '@/utils';
import { getDcVersion, isBeforeVersion } from '@/utils/dc-helper';
import { Di18nProvider, intl } from 'di18n-react';
import { useEffect, useMemo, useState, Suspense } from 'react';
import { withCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './initLocal';
import enUS from './language/en-US.json';
import zhCN from './language/zh-CN.json';
import { getTenantConfig, getCombineTenantInfo } from '@/pages/cooper/TenantLogin/service';
import { cleanLocalStorageIfNeeded } from '@/utils/localStorage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '@/assets/style/antd-init-all.less';
import errorNotice from './components/LiteModal';
import CooperAnnounceHalo from './components/CooperAnnounceHalo';
import ErrorBoundary from '@/components/ErrorBoundary';
import getBrowserInfo from '@/components/NeedUpdateBrowser/index';
import { get } from '@/utils/request/cooper';


// TODO:改成createBrowserRouter，才能使用一些hook


const locales = {
  'en-US': enUS,
  'zh-CN': zhCN,
};

function App() {
  const [supportPreview, setSupportPreview] = useState(true);
  const [cooperRoute, setCooperRoute] = useState([]);
  const [tenantId, setTenantId] = useState(null);
  const [dkRoute, setDkRoute] = useState([]);
  const [showAnnounce, setShowAnnounce] = useState(false);
  const [warningTip, setWarningTip] = useState({})
  const [haloClient, setHaloClient] = useState(null);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [globalRoute, setGlobalRoute] = useState([{
    path: '/tenant',
    name: () => '租户切换',
    key: 'global',
    exact: true,
    element: <Suspense fallback={<SkeletonPage />}><TenantLoginPage /></Suspense>,
  }]);

  const handleWarnTips = (obj) => {
    setWarningTip(obj?.bytesObj?.data?.cooper || {})
  }

  const handleVisibilityChange = () => {
    setIsPageVisible(!document.hidden);
  };

  useEffect(() => {
    import('@/service/knowledge/initHalo')
      .then((module) => {
        setHaloClient(module.default);
      })
      .catch((err) => {
        console.log('inithalo err', err);
      });
  }, []);


  useEffect(() => {
    if (isPageVisible) { // 页面可见
      // 当前未连接，或连接失败
      if (haloClient && haloClient?.haloState !== 2 && haloClient?.haloState !== 3) {
        haloClient.connect();
      }
    } else { // 页面不可见
      if (haloClient) {
        haloClient.close()
      }
    }
  }, [isPageVisible])

  useEffect(() => {
    if (haloClient) {
      haloClient.addEventListener('publishMessage', handleWarnTips);
    }
    return () => {
      if (haloClient) {
        haloClient.removeEventListener('publishMessage', handleWarnTips);
      }
    };
  }, [haloClient]);

  useEffect(async () => {
    // 监听页面关闭与打开
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // 开发环境需要手动设置 cookie
    if (process.env.APP_ENV === 'dev') {
      const { _username, _usertoken } = require('./cookie');
      setCookie('_user_token', _usertoken);
      setCookie('_cooper_username', _username);
      setCookie('_is_cooper_login', true);
      setCookie('_chain_user_token', _usertoken);
    }
    if (process.env.APP_ENV === 'qa' && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line import/extensions
      const { _username, _usertokenQa } = require('./cookie');
      setCookie('_user_token', _usertokenQa);
      setCookie('_cooper_username', _username);
      setCookie('_is_cooper_login', true);
      setCookie('_chain_user_token', _usertokenQa);
    }

    asyncInitSdk();
    setConnectedSiteId()
    addOsClassFlag();
    cleanLocalStorageIfNeeded();
    getCooperNotice();
    setShowAnnounce(judgePathname());
    store.dispatch.GlobalData.getCooperLinkConf();
    message.config({
      maxCount: 1,
      duration: 2,
      rtl: false,
    })
    getBrowserInfo();
    history.listen(() => {
      window.Omega?.sendPageView();
    });
    if (window.Omega) {
      masRecord(window.Omega, {})
    }

    // 监听登录状态变化
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) return;
      const tenantIdFromStore = store.getState().GlobalData.tenantId
      const tenantIdFromLocalStorage = window.localStorage.getItem('x-tenant-id')

      if (event.key === 'x-tenant-id') {
        // 测试使用 event.newValue
        if (tenantIdFromStore && tenantIdFromLocalStorage && tenantIdFromStore !== tenantIdFromLocalStorage) {
          if (window.location.pathname !== '/tenant') {
            window.location.reload()
          }
        }
      }
    });

    window.addEventListener('popstate', () => {
      getCooperNotice();
      setShowAnnounce(judgePathname());
    });
    return () => {
      window.removeEventListener('popstate', () => {
        getCooperNotice();
      });
    }
  }, []);


  // 全局保存搜索知识库信息，在layout里的context放数据会丢失
  useEffect(() => {
    setSupportPreview(isSupportPreview(window.location.pathname, isbeforevision));
  }, [window.location.pathname]);

  const isbeforevision = useMemo(() => {
    if (inPhone()) {
      const dcVersion = getDcVersion();
      return isBeforeVersion(dcVersion, '3.29.0');
    }
    return true;
  }, [window.navigator.userAgent]);

  const mobileSupport = useMemo(() => {
    return inPhone() && !supportPreview;
  }, [supportPreview]);

  const getCooperNotice = async () => {
    const data = await getCooperNoticeFromBackEnd();
    const result = await getCooperErrorFromBackEnd();
    if (data && window.location.pathname !== '/send-file') {
      if (inPhone() && window.location.pathname.includes('/knowledge')) {
        errorNotice('switch_knowledge');
        return;
      }
      errorNotice('switch');
      return;
    }
    if (result && window.location.pathname !== '/send-file') {
      errorNotice('error');
    }
  }

  /**
   * 添加操作系统class标识到body
   */
  const addOsClassFlag = () => {
    if (navigator.userAgent.indexOf('Win') !== -1) {
      // Windows-specific CSS styles
      document.body.classList.add('windows');
    } else if (navigator.userAgent.indexOf('Mac') !== -1) {
      // Mac-specific CSS styles
      document.body.classList.add('mac');
    } else if (navigator.userAgent.indexOf('Linux') !== -1) {
      // Linux-specific CSS styles
      document.body.classList.add('linux');
    } else {
      // Generic CSS styles
      document.body.classList.add('generic');
    }
  };
  useEffect(async () => {
    await getAndSetTenant()
    // 获取是否出现黄条提示
    const res = await get(api.GET_WARNING);
    setWarningTip(res?.cooper)
  }, []);

  const getAndSetTenant = async () => {
    const res = await getCombineTenantInfo();
    const tenantConfig = await getTenantConfig();

    // 获取完数据后删除骨架屏的dom，防止页面抖动
    const rootNode = document.getElementById('root');
    const skeletonRoot = document.getElementById('root-skeleton');
    if (skeletonRoot) {
      skeletonRoot.parentNode.removeChild(skeletonRoot);
      rootNode.style.display = 'flex';
    } else {
      console.log("Element with id 'root' not found.");
    }

    // 适用于cooper的用户list
    const tenantListCooper = res?.tenants?.filter((v) => v.useCooper);
    const tenantId = tenantListCooper.filter((v) => v.selected)[0]?.tenantId;
    // 如果当前未选中的节点，则需要跳转到租户选择页面
    if (!tenantId && window.location.pathname !== '/tenant') {
      window.location.href = '/tenant'
    }
    if (tenantId) {
      setTenantId(tenantId)
      store.dispatch({
        type: 'GlobalData/setTenantId',
        payload: tenantId,
      });
      store.dispatch({
        type: 'GlobalData/setIsExternalTenant',
        payload: tenantId !== 1,
      });
      store.dispatch({
        type: 'GlobalData/setTenantList',
        payload: tenantListCooper,
      });
      store.dispatch({
        type: 'GlobalData/setTenantConfig',
        payload: tenantConfig,
      });

      store.dispatch({
        type: 'CooperIndex/setProfile',
        payload: {
          avatar: res.avatar,
          email: res.email,
          orgMemberId: res.orgMemberId,
          tenantCode: res.tenantCode,
          tenantId: res.tenantId,
          tenantName: res.tenantName,
          uid: res.uid,
          username: res.username,
          username_zh: res.username_zh,
        },
      });

      setCookie('_cooper_username', res.username, 24 * 60 * 60);
      setCookie('_cooper_user_uid', res.uid, 24 * 60 * 60);
      setCookie('_cooper_user_orgMemberId', res.orgMemberId, 24 * 60 * 60);
      window.__OmegaEvent(
        'ep_cooper_login_ck',
        '多租户',
        {
          orgid: res.tenantId,
          orgname: res.tenantName,
        },
      );
      setCooperRoute(getCooperRoute().route)
      setGlobalRoute(getGlobalRoute().globalRoute)
      setDkRoute(getDkRoute().routesDK)
    }
    // else if (window.location.pathname !== '/tenant') {
    //   window.location.href = '/tenant'
    // }
  }
  // 此处可以留着调试需要注册的路由是否准确
  // useEffect(() => {
  //   console.log(cooperRoute, globalRoute, dkRoute);
  // }, [cooperRoute, globalRoute, dkRoute])

  // eslint-disable-next-line consistent-return
  const isOpenDcWebView = () => {
    // eslint-disable-next-line no-constant-condition
    if (inPhone() && store.getState().GlobalData.IsExternalTenant) {
      window.dcH5Sdk.navigation.openWebview({
        url: window.location.origin,
        onSuccess() {},
        onFail() {},
      });
    } else {
      return (<Route
        path="*"
        key='default'
        element={<Navigate to="/" />}
      />)
    }
  };
  const showAnnounceWithoutSpecialPath = () => {
    const { pathname } = window.location
    if (pathname.indexOf('/send-file') !== -1) {
      return false
    }
    return true
  }

  const judgePathname = () => {
    if (
      window.location.pathname === '/'
      || window.location.pathname.includes('/disk')
      || window.location.pathname.includes('/files')
      || window.location.pathname.includes('/team-folder')
      || window.location.pathname.includes('/team-folder')
      || window.location.pathname.includes('/team-file')
      || window.location.pathname.includes('/tome')
      || window.location.pathname.includes('/fromme')
      || window.location.pathname.includes('/favorite')
      || window.location.pathname.includes('/teams/invite')
    ) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line consistent-return
  const routerViews = (routerItems) => {
    if (routerItems && routerItems.length) {
      return routerItems.map(({ path, element, exact, children, redirect }) => {
        return children && children.length ? (
          <Route
            path={path}
            exact={exact}
            key={path}
            element={element}>
            {routerViews(children)}
            {redirect
              ? (<Route
                  path={path}
                  key={path}
                  element={<Navigate to={redirect} />} />)
              : (<Route
                  path={path}
                  key={path}
                  element={<Navigate to={children[0].path} />} />)
            }
          </Route>
        ) : (
          <Route
            path={path}
            exact={exact}
            key={path}
            element={element} />
        )
      })
    }
  }

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Di18nProvider
          locales={locales}
          cookieLocaleKey='local_language'
      >
          <Provider store={store}>
            <ConfigProvider
            // prefixCls="dkCustom"
              autoInsertSpaceInButton={false}
              componentSize={'middle'}
              input={{ autoComplete: 'off' }}
            >
              {
                showAnnounceWithoutSpecialPath() && (!!tenantId && tenantId === 1 ? <CooperAnnounceHalo tips={warningTip?.inner} /> : <CooperAnnounceHalo tips={warningTip?.outer} />)
              }
              {mobileSupport && (
                <ErrorTips
                  img={PhoneNoAccessIcon}
                  title={intl.t('暂不支持访问')}
                  desc={
                  isbeforevision
                    ? intl.t('移动端暂不支持访问知识库回收站、设置、数据看板，如需访问知识库目录及内容，请升级D-Chat至最新版本或前往PC端访问')
                    : intl.t('移动端暂不支持访问知识库回收站、设置、数据看板，请前往PC端访问')
                  }
                  overlayClassName={'error-tip-inPhone'}
                />)
              }
              {
                !mobileSupport && <BrowserRouter history={history}>
                  <Routes>
                    {
                      cooperRoute.length !== 0 ? <Route
                        path="/"
                        key='all'
                        element={<LayoutCooper />}
                    >
                        {routerViews(cooperRoute)}
                      </Route> : null
                    }
                    {
                      dkRoute.length !== 0 ? <Route
                        path="/"
                        element={<LayoutDK />}
                        key='dk'
                      >
                        {routerViews(dkRoute)}
                      </Route> : null
                    }
                    {
                      globalRoute.length !== 0 ? <Route
                        path="/"
                        key='noLayout'
                    >
                        {routerViews(globalRoute)}
                      </Route> : null
                    }
                    {
                      // eslint-disable-next-line max-len
                      cooperRoute.length !== 0 && dkRoute.length !== 0 && globalRoute.length !== 0 ? isOpenDcWebView() : null
                    }
                  </Routes>
                </BrowserRouter>
              }
            </ConfigProvider>
          </Provider>
        </Di18nProvider >
      </DndProvider>
    </ErrorBoundary>

  );
}

export default withCookies(App);
